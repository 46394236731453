import React, { FunctionComponent } from 'react';
import { Link } from './Link';
import { Image, cloudfrontUrl } from './Image';
import { Icon, IconTextButton } from '@borrowmydoggy/core-components';

import { faChevronRight, faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export interface IHeroProps {
	heading: string;
	textBlocks: string[];
	buttonTextBorrower: string;
	buttonTextOwner: string;
	scrollDownLabel: string;
	children?: React.ReactNode;
}

export const Hero: FunctionComponent<IHeroProps> = ({ textBlocks, buttonTextBorrower, buttonTextOwner, scrollDownLabel }: IHeroProps) => {
	return (
		<div
			className='flex flex-col items-center justify-center
				bg-cover bg-no-repeat bg-[top_-160px_right_0px]
				md:bg-[top_-14vw_right_0px]
				min-h-[30vw] md:h-[50vw] max-h-[800px] relative'
			style={{
				backgroundImage: `url(${cloudfrontUrl}/images/content/hero/retreiver_with_owner_sitting.jpg)`
			}}
		>
			<div className='bg-white
				px-8 py-10
				flex flex-col items-center justify-center md:justify-evenly gap-5
				w-full box-border md:box-content
				mt-[400px] md:m-20
				md:mr-[40vw]
				md:w-[50vw] md:h-[35vw]
				md:max-w-[620px] md:max-h-[460px]
				md:rounded-xl'>
				<h1 className='w-full flex flex-col items-center'> {/* h1 wrapper means alt text should be used for SEO purposes. */}
					<Image path='images/content/hero/share_the_love_white_bg.svg' alt='Share the love of dogs' className='max-w-[300px] md:w-[43vw] md:mt-[3vw] md:max-w-[530px]' />
				</h1>

				<div className='flex flex-col items-center justify-center text-center gap-5 max-w-[435px] md:w-[300px] lg:w-[435px] max-width-[90vw]'>
					{textBlocks.map((subHeading: string, index: number) => (
						<span key={index} className='text-slate-600'>
							{subHeading}
						</span>
					))}
				</div>

				<div className='grid grid-flow-cols grid-rows-2 md:grid-flow-rows md:grid-cols-2 gap-5 lg:gap-10 md:mt-5'>
					<Link href='https://www.borrowmydoggy.com/signup?pt=borrower' looksLike='button' className='py-6 px-9'>
						{buttonTextBorrower}
						<Icon definition={faChevronRight} className='text-yellow-300 pl-2' />
					</Link>

					<Link href='https://www.borrowmydoggy.com/signup?pt=owner' looksLike='button' className='py-6 px-9'>
						{buttonTextOwner}
						<Icon definition={faChevronRight} className='text-yellow-300 pl-2' />
					</Link>
				</div>
			</div>
			<div className='bg-white px-8 flex flex-col w-full box-border items-center justify-center md:hidden'>
				<hr className='w-full border-solid border-0 border-t border-slate-600' />
			</div>
			<Link href='#owner-or-borrower' looksLike='anchor' className='bg-white text-slate-600 rounded-lg py-3 px-16 absolute bottom-[-1rem] md:bottom-[-2.5rem]'>
				{scrollDownLabel}
				<Icon definition={faChevronDown} className='text-slate-600 pl-2' />
			</Link>
		</div>
	);
};

export default Hero;
